import type { LayoutType } from '@local-types/content/layout';

export const DEFAULT_MAX_WIDTH = '1600px';

const gridStyles = {
  maxWidth: DEFAULT_MAX_WIDTH,
  mx: 'auto',
  width: 1,
};

// eslint-disable-next-line complexity
export const getSliceStyles = (type: string | LayoutType): object => {
  switch (type) {
    case 'content-product-list':
      return {
        ...gridStyles,
        px: ['sp_8', 'sp_12', null, 'sp_16'],
      };
    case 'top-banner':
      return {
        ...gridStyles,
        flexDirection: 'column',
        display: 'flex',
      };
    case 'common-tab-bar':
    case 'common-visual-navigation':
    case 'recommendations-slider':
    case 'articles-navigation':
    case 'content-slider':
    case 'voucher-promotions':
    case 'content-article-header':
    case 'showroom-outlet-heading':
      return {
        ...gridStyles,
        px: ['sp_16', 'sp_24', 'sp_48', 'sp_64'],
      };
    case 'seo-section':
    case 'newsletter-signup':
      return { mb: ['sp_16', 'sp_24', null, 'sp_32'], width: 1 };
    case 'brands-glossary':
      return {
        ...gridStyles,
        mt: ['sp_20', null, 'sp_24', 'sp_32'],
        px: ['sp_16', 'sp_24', 'sp_48', 'sp_64'],
      };
    case 'homepage-top-teaser':
      return {
        ...gridStyles,
        pb: ['sp_24', '28px', 'sp_24'],
      };
    case 'common-inspirational-teaser':
      return {
        ...gridStyles,
        px: ['sp_8', 'sp_24', 'sp_48', 'sp_64'],
      };
    case 'offer-teaser':
      return {
        ...gridStyles,
        px: ['sp_0', 'sp_24', 'sp_48', 'sp_64'],
      };
    default:
      return {
        mx: 'auto',
        width: 1,
      };
  }
};
