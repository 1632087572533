import { Fragment } from 'react';

import type { StringMap } from 'corgi-types';
import ArticlesNavigation from 'corgi/articles-navigation/components/ArticlesNavigation';
import BrandsGlossary from 'corgi/brands-glossary/components/BrandsGlossary';
import CardGrid from 'corgi/card-grid/components/CardGrid';
import CheckGiftCardBalanceForm from 'corgi/check-gift-card-balance-form/components/CheckGiftCardBalanceForm';
import CommonBanner from 'corgi/common-banner/components/CommonBanner';
import CommonInspirationalTeaser from 'corgi/common-inspirational-teaser/components/CommonInspirationalTeaser';
import CommonTabBar from 'corgi/common-tab-bar/components/CommonTabBar';
import CommonUspTeaser from 'corgi/common-usp-teaser/components/CommonUspTeaser';
import CommonVisualNavigation from 'corgi/common-visual-navigation/components/CommonVisualNavigation';
import { PageGridItem } from 'corgi/common/components/PageGrid';
import ContentArticleHeader from 'corgi/content-article-header/components/ContentArticleHeader';
import ContentProductList from 'corgi/content-product-list/components/ContentProductList';
import ContentSlider from 'corgi/content-slider/components/ContentSlider';
import CtaButtons from 'corgi/cta-buttons/components/CtaButtons';
import GlobalRibbon from 'corgi/global-ribbon/components/GlobalRibbon';
import HomeClubSection from 'corgi/home-club-section/components/HomeClubSection';
import HomepageTopTeaser from 'corgi/homepage-top-teaser/components/HomepageTopTeaser';
import ListNavigation from 'corgi/list-navigation/components/ListNavigation';
import NewsletterSignup from 'corgi/newsletter-signup/components/NewsletterSignup';
import OfferTeaser from 'corgi/offer-teaser/components/OfferTeaser';
import PictureText from 'corgi/picture-text/components/PictureText';
import RecommendationsSlider from 'corgi/recommendations-slider/components/RecommendationsSlider';
import SeoSection from 'corgi/seo-section/components/SeoSection';
import ShopInShopNavigation from 'corgi/shop-in-shop-navigation/components/ShopInShopNavigation';
import ShowroomOutletHeading from 'corgi/showroom-outlet-heading/components/ShowroomOutletHeading';
import TopBanner from 'corgi/top-banner/components/TopBanner';
import TopNewsSection from 'corgi/top-news-section/components/TopNewsSection';
import VoucherPromotions from 'corgi/voucher-promotions/components/VoucherPromotions';

import type { ComponentConfig, LayoutType } from '@local-types/content/layout';
import { getSliceStyles } from './styles';

const sliceComponentMap: StringMap = {
  'common-visual-navigation': CommonVisualNavigation,
  'seo-section': SeoSection,
  'top-banner': TopBanner,
  'card-grid': CardGrid,
  'common-usp-teaser': CommonUspTeaser,
  'content-product-list': ContentProductList,
  'picture-text': PictureText,
  'common-tab-bar': CommonTabBar,
  'newsletter-signup': NewsletterSignup,
  'cta-buttons': CtaButtons,
  'top-news-section': TopNewsSection,
  'articles-navigation': ArticlesNavigation,
  'content-slider': ContentSlider,
  'voucher-promotions': VoucherPromotions,
  'content-article-header': ContentArticleHeader,
  'brands-glossary': BrandsGlossary,
  'showroom-outlet-heading': ShowroomOutletHeading,
  'homepage-top-teaser': HomepageTopTeaser,
  'recommendations-slider': RecommendationsSlider,
  'common-inspirational-teaser': CommonInspirationalTeaser,
  'offer-teaser': OfferTeaser,
  'list-navigation': ListNavigation,
  'global-ribbon': GlobalRibbon,
  'home-club-section': HomeClubSection,
  'check-gift-card-balance-form': CheckGiftCardBalanceForm,
  'shop-in-shop-navigation': ShopInShopNavigation,
  'common-banner': CommonBanner,
};

const getComponentWrapper = (type: string | LayoutType) => {
  switch (type) {
    // Note: Shop-in-shop navigation has a non-sticky banner and sticky nav bar. Wrapping it in
    // PageGridItem limits sticky behavior to that element (same size as nav), negating the effect.
    // Making PageGridItem sticky also affects the banner. Therefore, no full wrap.
    case 'shop-in-shop-navigation':
      return Fragment;
    default:
      return PageGridItem;
  }
};

const ComponentSlice = ({ sliceConfig }: { sliceConfig: ComponentConfig }) => {
  const { corgiType, props } = sliceConfig;

  const CorgiComponent = sliceComponentMap[corgiType];
  const ComponentWrapper = getComponentWrapper(corgiType);
  const sliceStyles = getSliceStyles(corgiType);

  return (
    <ComponentWrapper mb={['sp_16', 'sp_20', 'sp_24', 'sp_32']} {...sliceStyles}>
      {CorgiComponent && <CorgiComponent {...props} />}
    </ComponentWrapper>
  );
};

export default ComponentSlice;
